<template>
    <div>
        <component v-if="component" :is="component" v-bind="componentParams"></component>
    </div>
</template>

<script>
import '../plugins/axios'
import store from '../store'

export default {
    name: "QuestionnaireForm",
    props: ['isStatic'],
    store,
    components: {
    },
    data() {
        return {
            show: true,
            countClick: {},
        };
    },
    computed: {
        component() {
            if (!Object.keys(this.$store.state.questionnaire).length || !this.show) return null;

            if(!this.isStatic) {
                if (!this.$store.state.questionnaire.is_static) {
                    return () => import('@/components/questionnaire/main.vue');
                }
                return null
            } else {
                if (this.$store.state.questionnaire.is_static) {
                    return () => import('@/components/questionnaire/static.vue');
                }
                return null
            }
        },
        componentParams() {
            return {
                questions: this.$store.state.questionnaire ? this.$store.state.questionnaire.questions : []
            }
        },
    },
    methods: {
        getQuestionnaire() {
            return this.$store.dispatch('getQuestionnaires').then(data => {
                if (data.questions) {
                    let find = false;
                    data.questions.forEach(elem => {
                        if (elem.type === 6) {
                            find = true;
                        }
                    });
                    if (!find) {
                        this.$store.state.questionnaire.questions.push({
                            id:null,
                            text: 'Дякую за вашу відповідь!',
                            type: 6,
                            answers: [],
                        })
                    }
                }
                return data;
            });
        },
        getEvents() {
            return this.$store.dispatch('getEvents').then(data => {
                let list = []; let query = ''; let id = 0;
                if (data) {
                    console.log('getEvents');
                    let func = () => {
                        data.forEach(event => {
                            if (event.elements) {
                                event.elements.forEach(elem => {
                                    query = '';
                                    if (elem.settings.class) {
                                        query = `.${elem.settings.class}`;
                                    }
                                    if (elem.settings.href) {
                                        query += `[href="${elem.settings.href}"]`;
                                    }
                                    list = document.querySelectorAll(query);
                                    if (list) {
                                        for(let i = 0; i < list.length; i++) {
                                            this.countClick[elem.id] = 1;
                                            list[i].addEventListener('click', () => {
                                                if (event.count_click && event.count_click > this.countClick[elem.id]){
                                                    this.countClick[elem.id]++;
                                                } else {
                                                    this.countClick[elem.id] = 1;
                                                    if (event.questionnaires) {
                                                        let questionnaire = null;
                                                        let show = false;
                                                        if (event.questionnaires.length > 1) {
                                                            questionnaire = event.questionnaires[Math.floor(Math.random() * event.questionnaires.length)];
                                                            id = questionnaire.id;
                                                        } else {
                                                            questionnaire = event.questionnaires[0];
                                                            id = questionnaire.id;
                                                        }
                                                        if (questionnaire.settings) {
                                                            questionnaire.settings.forEach(elem => {
                                                                switch (elem.key) {
                                                                    case 'timer_and_show_event':
                                                                        show = true;
                                                                        setTimeout(() => {
                                                                            this.$store.commit('activeEvent', event.id);
                                                                            this.$store.dispatch('getQuestionnaire', {id, eventId: event.id});
                                                                        }, +elem.value * 1000);
                                                                        break;
                                                                }
                                                            });
                                                        }
                                                        if(!show){
                                                            this.$store.commit('activeEvent', event.id);
                                                            this.$store.dispatch('getQuestionnaire', {id, eventId: event.id});
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                    }
                                });
                            }
                        });
                    }
                    setTimeout(func, 1000);
                }
            });
        }
    },
    created() {
        this.$store.dispatch('updateUser');
        this.getQuestionnaire().then(data => {
            if (!data.id) return;
            if (data.settings) data.settings.forEach(elem => {
                switch (elem.key) {
                    case 'timer_and_show':
                        this.show = false;
                        setTimeout(() => {
                            this.show = true;
                            this.$store.dispatch('getQuestionnaire', {id: data.id});
                        }, +elem.value * 1000);
                        break;
                    case 'show_footer':
                        if ((elem.value != 'false') && ((document.body.offsetHeight - window.innerHeight) > 0)) {
                            this.show = false;
                            addEventListener("scroll", () => {
                                let offset = document.body.offsetHeight - window.innerHeight;
                                if ((offset * 0.75) <= window.scrollY) {
                                    this.$store.dispatch('getQuestionnaire', {id: data.id});
                                    this.show = true;
                                }
                            });
                        }
                        break;
                    case 'whom_show':
                        if ((elem.value == 'enrichment') || (elem.value == 'not')) {
                            this.show = false;
                            let timer = null;
                            window.addEventListener('userDataLoaded', () => {
                                if (window.userData.phoneStatus && (elem.value == 'enrichment')) {
                                    this.show = true;
                                } else if((window.userData.phoneStatus == -1) && elem.value == 'not') {
                                    clearTimeout(timer);
                                    this.show = true;
                                    this.$store.dispatch('getQuestionnaire', {id: data.id});
                                }
                            });
                            if (elem.value == 'not') {
                                timer = setTimeout(() => {
                                    if (window.userData.phoneStatus == -1) {
                                        this.$store.dispatch('getQuestionnaire', {id: data.id});
                                        this.show = true;
                                    }
                                }, 30000);
                            }
                        }
                        break;
                }
            });
            if (this.show) {
                this.$store.dispatch('getQuestionnaire', {id: data.id});
            }
        });
        if (!this.isStatic) {
            this.getEvents();
        }
    }
}
</script>

<style lang="sass">
@import "../assets/sass/questionnaire.scss";
</style>
